import { createMuiTheme } from '@material-ui/core/styles';

const mint = '#99D8BC';
const roseGold = '#E7B2A5';
const gold = '#D4AF37';

const theme = createMuiTheme({
    palette: {
        common: {
            mint: `${mint}`,
            roseGold: `${roseGold}`,
            gold: `${gold}`
        },
        primary: {
            main: `${mint}`
        },
        secondary: {
            main: `${roseGold}`
        },
        action: {
            selected: 'rgba(0,0,0,0.3)'
        },
        text: {
            primary: 'rgba(231, 178, 165,0.87)'
        }
    },
    typography: {
        tab: {
            fontFamily: 'Roboto',
            textTransform: 'none',
            fontWeight: '700',
            fontSize: '1rem'
        }
    }
});

export default theme;