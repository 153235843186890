import React, { useEffect, useState } from 'react';
import {connect} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import PrayerCard from '../components/cards/PrayerCard';
import PrayerHeader from '../components/cards/PrayerHeader';
import AddNewPrayerCard from './cards/AddNewPrayerCard';
import firebase from '../Firebase';
import * as actions from '../store/actions';
import {itemsPerPage, classId} from '../settings';
import PrayerFilters from './cards/PrayerFilters';


const useStyles = makeStyles(theme => ({
    root: {
        //backgroundColor: theme.palette.secondary.main
        //backgroundColor: theme.palette.common.gray
    }
}));

const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

const ClassPrayerJournal = (props) => {
    const classes = useStyles();
    const [addingNew, setAddingNew] = useState(false);
    const [prayers, setPrayers] = useState([]);
    const [totalPrayers, setTotalPrayers] = useState(0);
    const [totalAnswered, setTotalAnswered] = useState(0);
    const [nextDate, setNextDate] = useState(null);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState(0);
    const {fetchClassPrayers} = props;
    
    const deletePostHandler = (key) => { 
        firebase.database().ref(`/PublicPrayerJournal/${classId}`).child(key).remove()
            .then(() => {
                // handling the last delete so that the totals show up correctly
                firebase.database().ref(`/PublicPrayerJournal/${classId}`).once('value', (snapshot) => {
                    if (!snapshot.hasChildren()) {
                        setTotalPrayers(0);
                        setTotalAnswered(0);
                    }
                    }, (error) => {
                        console.log(error);
                    })
            })
            .catch((error) => {
            })
    };

    const markPrayerHandler = (key, answered) => {
        firebase.database().ref(`/PublicPrayerJournal/${classId}`).child(key).update({answered: answered, answeredDate: + new Date()})
        .then(() => {
        })
        .catch((error) => {
        })
    };
    
    const loadMoreHandler = () => {
        setPage(page + 1);
    };
    
    const cancelHandler = () => {
        setAddingNew(false);
    };

    useEffect(() => {
        const filterOpts = {
            type: filter,
            page: page,
            uid: props.uid,
            path: `/PublicPrayerJournal/${classId}`
        };

        fetchClassPrayers(filterOpts);
    }, [fetchClassPrayers, page, props.uid, filter]);

    useEffect(() => {
        const prayerArr = props.prayers;

        if (prayerArr.length > page * itemsPerPage) {
            prayerArr.pop();
            setNextDate(true);
        } else {
            setNextDate(false);
        }

        setPrayers(prayerArr);
    }, [props.prayers, page]);

    useEffect(() => {
        const ref = firebase.database().ref(`Stats/${classId}`);

        ref.on('value', (snapshot) => {
            const stats = snapshot.val();
            if (stats) {
                setTotalPrayers(stats.classPJCount);
                setTotalAnswered(stats.classPJAnsweredCount);
            }
        })
        
        return () => { ref.off(); }
    }, []);

    useEffect(() => {
        setPage(1);
    }, [filter])

    const prayerItems = prayers ? prayers.map(element => (
        <Grid item xs={11}
            key={element.key} >
            <PrayerCard
                prayerId={element.key}
                owner={props.uid === element.user} 
                role={props.role}
                answered={element.answered}
                answeredDate={new Date(element.answeredDate).toLocaleDateString(undefined, options)}
                author={element.author}
                body={element.body}
                delete={deletePostHandler}
                markAnswered={markPrayerHandler}
                date={new Date(element.date).toLocaleDateString(undefined, options)}
                />
        </Grid>
    )) : [];

    const spinner = (
        <Grid container item justify="center">
            <CircularProgress color="secondary" />
        </Grid>
    );

    const addNewButton = (
        <Grid container item justify="center">
            <Button color="primary" onClick={() => setAddingNew(true)} variant="outlined" fullWidth >
                Add New Prayer Request
            </Button>
        </Grid>
    );

    const loadMoreButton = (
        <Grid container item justify='center'>
            <Button color='primary' onClick={loadMoreHandler} variant='outlined' fullWidth >
                Load More
            </Button>
        </Grid>
    );

    return (
        <div style={{minHeight: '500px', padding: '20px 0px'}} className={classes.root}>
            <Grid container direction="column" justify="center" alignItems="center" >
                <Grid item container xs={12} sm={10} md={8} lg={6} xl={4} spacing={2} justify="center" alignContent="center">
                    <Grid item xs={11}>
                        <PrayerHeader isAuth={props.isAuth} total={totalPrayers} totalAnswered={totalAnswered} title="Ladies With Purpose" subheader="Class Prayer Journal" />
                    </Grid>
                    <Grid item xs={11}>
                        <PrayerFilters filter={filter} setFilter={setFilter} variant="public"/>
                    </Grid>
                    <Grid item xs={11}>
                        {addingNew ? <AddNewPrayerCard cancel={cancelHandler} path={`/PublicPrayerJournal/${classId}`} /> : props.isAuth && filter !== 1 ? addNewButton : null}
                    </Grid>
                    {prayerItems ? prayerItems : null}
                    {props.isLoading && props.isAuth ? spinner : null}
                    <Grid item xs={11}>
                        {nextDate ? loadMoreButton : null}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
        isLoading: state.classPrayer.loading,
        prayers: state.classPrayer.classPrayers,
        role: state.auth.role,
        uid: state.auth.userId
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchClassPrayers: (filterOpts) => dispatch(actions.fetchClassPrayers(filterOpts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassPrayerJournal);