import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%'
    },
    recentTab: {
        height: '100%',
        color: theme.palette.primary.main,
        minWidth: '28%'
    },
    answeredTab: {
        height: '100%',
        color: theme.palette.primary.main,
        minWidth: '34%'
    },
    allAndYouTab: {
        height: '100%',
        color: theme.palette.primary.main,
        minWidth: '19%'
    },
    tabSelected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper
    },
    tabs: {
        minWidth: '100%',
        height: '100%'
    },
    indicator: {
        display: 'none'
    },
    private: {
        height: '100%',
        color: theme.palette.primary.main,
        minWidth: '33%'
    },
    privateSelected: {
        height: '100%',
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        minWidth: '33%'
    }
}));


const PrayerFilters = (props) => {
    const classes = useStyles();

    const onChangeHandler = (event, newValue) => {
        props.setFilter(newValue);
    }

    const privateTabs = (
        <Tabs value={props.filter} className={classes.tabs} classes={{indicator: classes.indicator}} onChange={onChangeHandler} >
            <Tab label="Unanswered" className={props.filter === 0 ? [classes.private, classes.privateSelected].join(' ') : classes.private} />
            <Tab label="All" className={props.filter === 1 ? [classes.private, classes.privateSelected].join(' ') : classes.private} />
            <Tab label="Answered" className={props.filter === 2 ? [classes.private, classes.privateSelected].join(' ') : classes.private} />
        </Tabs>
    );

    const publicTabs = (
        <Tabs value={props.filter} className={classes.tabs} classes={{indicator: classes.indicator}} onChange={onChangeHandler} >
            <Tab label="Recent" className={props.filter === 0 ? [classes.recentTab, classes.tabSelected].join(' ') : classes.recentTab} variant='fullWidth' />
            <Tab label="Answered" className={props.filter === 1 ? [classes.answeredTab, classes.tabSelected].join(' ') : classes.answeredTab} />
            <Tab label="All" className={props.filter === 2 ? [classes.allAndYouTab, classes.tabSelected].join(' ') : classes.allAndYouTab} />
            <Tab label="You" className={props.filter === 3 ? [classes.allAndYouTab, classes.tabSelected].join(' ') : classes.allAndYouTab} />
        </Tabs>
    );

    return (
        <Paper variant='outlined' className={classes.paper} color='white'>
            {props.variant === 'public' ? publicTabs : null}
            {props.variant === 'private' ? privateTabs : null}
        </Paper>
    );
};

export default PrayerFilters;