import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDPBfS-W19ZJM8Las-U6cn4zh-F68SCzaE",
    authDomain: "ladies-with-purpose.firebaseapp.com",
    databaseURL: "https://ladies-with-purpose.firebaseio.com",
    projectId: "ladies-with-purpose",
    storageBucket: "ladies-with-purpose.appspot.com",
    messagingSenderId: "846548222797",
    appId: "1:846548222797:web:97429d58c6263ca1472d04"
};

export default !firebase.apps.length ? firebase.initializeApp(firebaseConfig) : firebase.app();