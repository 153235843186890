import React, { Fragment, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles } from '@material-ui/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Link, withRouter } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Slide from '@material-ui/core/Slide';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import logo from '../../assets/logoWithImage.png';

function HideOnScroll(props) {
    const { children, window } = props;
    
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
};

const types = {tab: 0, menu: 1};

const initializeState = (headerObject) => {
    let state = {};
    headerObject.forEach(element => {
        if (element.type === types.menu) {
            state = {...state, [element.name]: {open: false, anchorEl: null}};
        }
    });
    return state;
};

const getTabValue = (arr, link) => {
    let value = 0;
    
    arr.forEach((element, index) => {
        if (element.type === types.tab && element.path === link) {
            value = index;
        } else if (element.type === types.menu) {
            element.menuItems.forEach(mi => {
                if (mi.path === link) {
                    value = index;
                }
            });
        }
    });

    if (link === '/login') {
        value = arr.length;
    }

    return value;
};

const memberObj = [
    {name: 'Home', path: '/', type: types.tab},
    {name: 'Prayer Journal', type: types.menu, menuItems: [
        {name: 'Class Prayer Journal', path: '/classprayers'},
        {name: 'Private Prayer Journal', path: '/personalprayers'}
    ]},
    {name: 'Resources', path: '/resources', type: types.tab}
];
const adminObj = [
    {name: 'Home', path: '/', type: types.tab},
    {name: 'Prayer Journal', type: types.menu, menuItems: [
        {name: 'Class Prayer Journal', path: '/classprayers'},
        {name: 'Private Prayer Journal', path: '/personalprayers'}
    ]},
    {name: 'Resources', path: '/resources', type: types.tab},
    {name: 'Admin', path: '/admin', type: types.tab}
];
const miscPaths = [
    '/contact',
    '/privacy'
];

const useStyles = makeStyles(theme => ({
    drawer: {
        backgroundColor: theme.palette.secondary.main
    },
    drawerIcon: {
        height: 50,
        width: 50,
        color: theme.palette.background.paper
    },
    drawerIconContainer: {
        marginLeft: 'auto'
    },
    drawerItem: {
        ...theme.typography.tab,
        color: theme.palette.background.paper,
        opacity: 0.7
    },
    drawerItemSelected: {
        opacity: 1
    },
    emptyTab: {
        padding: 0,
        minWidth: 0
    },
    logoContainer: {
        cursor: 'pointer',
        marginLeft: '20px',
        height: '8rem',
        backgroundImage: `url(${logo})`,
        width: '20rem',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        [theme.breakpoints.down('md')]: {
            height: '7rem',
            width: '18rem'
        },
        [theme.breakpoints.down('sm')]: {
            height: '6rem',
            width: '16rem'
        },
        [theme.breakpoints.down('xs')]: {
            height: '5rem',
            width: '15rem'
        },
        "&:hover": {
            backgroundColor: 'transparent'
        }
    },
    menu: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white'
    },
    menuItem: {
        ...theme.typography.tab,
        opacity: 0.7,
        "&:hover": {
            opacity: 0.8
        }
    },
    menuItemSelected: {
        opacity: 0.7
    },
    tab: {
        ...theme.typography.tab,
        minWidth: 10,
        marginLeft: '25px',
        color: theme.palette.background.paper
    },
    tabContainer: {
        marginLeft: 'auto',
        margin: '0px 10px'
    },
    toolbarMargin: {
        ...theme.mixins.toolbar,
        marginBottom: '4rem',
        [theme.breakpoints.down('md')]: {
            marginBottom: '3rem'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '2rem'
        },
        [theme.breakpoints.down('xs')]: {
            marginBottom: '1.5rem'
        }
    }
}));

const Header = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDrawer, setOpenDrawer] = useState(false);
    const headerObject = (props.role === 5 ? adminObj : memberObj);
    const [menuState, setMenuState] = useState(initializeState(headerObject));

    const logoutHandler = () => {
        if(props.isAuth) {
            props.signUserOut();
        }
    };

    const handleNav = (path) => {
        setOpenDrawer(false);
        props.setCurrentPath(path);
    };

    useEffect(() => {
        const curPath = window.location.pathname;
        let array = [];
        headerObject.forEach(element => {
            if (element.type !== types.menu) {
                array.push(element.path);
            } else {
                element.menuItems.forEach(menuItem => {
                    array.push(menuItem.path);
                });
            }
        });
        array = array.concat(miscPaths);
        array.push('/login');

        if (array.includes(curPath)) {
            props.setCurrentPath(curPath);
        } else {
            props.setCurrentPath('/');
        }
    }, [props.setCurrentPath, props, headerObject]);

    const authDrawerItem = () => {
        const link = props.isAuth ? "/" : "/login";
        
        return (
            <ListItem
                key="authListItem"
                divider
                button
                onClick={() => logoutHandler()}
                component={Link}
                to={props.isAuth ? "/" : "/login"}
                selected={props.link === link && link !== "/"}>
                <ListItemText
                    key="authListItemText"
                    className={(props.link === link && link !== "/" )? [classes.drawerItem, classes.drawerItemSelected].join(' ') : classes.drawerItem}
                    onClick={() => setOpenDrawer(false)}>
                    {props.isAuth ? "Logout" : "Login"}
                </ListItemText>
            </ListItem>
        );
    };

    const listArray = () => {
        const array = [];
        headerObject.forEach(element => {
            if (element.type === types.tab) {
                array.push(
                    <ListItem
                        key={element.name + '-listItem'}
                        divider
                        button
                        component={Link}
                        to={element.path}
                        onClick={() => handleNav(element.path)}
                        selected={props.link === element.path}
                    >
                        <ListItemText
                            key={element.name + '-listItemText'}
                            className={props.link === element.path ? [classes.drawerItem, classes.drawerItemSelected].join(' ') : classes.drawerItem}>
                            {element.name}
                        </ListItemText>
                    </ListItem>
                );
            } else {
                element.menuItems.forEach(menuItem => {
                    array.push(
                        <ListItem
                            key={menuItem.name + '-listItem'}
                            divider
                            button
                            component={Link}
                            to={menuItem.path}
                            onClick={() => handleNav(menuItem.path)}
                            selected={props.link === menuItem.path}
                        >
                            <ListItemText
                                key={element.name + '-listItemText'}
                                className={props.link === menuItem.path ? [classes.drawerItem, classes.drawerItemSelected].join(' ') : classes.drawerItem}>
                                {menuItem.name}
                            </ListItemText>
                        </ListItem>
                    );
                });
            }
        });

        return array;
    }

    const list = (
        <List disablePadding>
            {listArray()}
            {authDrawerItem()}
        </List>
    );

    const drawer = (
        <Fragment>
            <SwipeableDrawer
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS}
                classes={{paper: classes.drawer}}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onOpen={() => setOpenDrawer(true)}
            >
                {list}
            </SwipeableDrawer>
            <IconButton
                className={classes.drawerIconContainer}
                onClick={() => setOpenDrawer(!openDrawer)} disableRipple>
                <MenuIcon className={classes.drawerIcon} />
            </IconButton>
        </Fragment>
    );

    const tabArray = () => {
        const array = [];
        headerObject.forEach(element => {
            if (element.type === types.tab) {
                array.push(<Tab key={element.name + '-tab'} label={element.name} className={classes.tab} onClose={() => handleNav(element.path)} component={Link} to={element.path} />);
            } else if (element.type === types.menu) {
                array.push(<Tab key={element.name +'-tab'}
                    aria-owns={menuState[element.name].anchorEl ? element.name + '-menu' : undefined}
                    aria-haspopup={menuState[element.name].anchorEl ? "true" : undefined}
                    label={element.name}
                    onClick={(e) => setMenuState({...menuState, [element.name]: {open: true, anchorEl: e.currentTarget}})}
                    className={classes.tab} />);
            }
        });

        return array;
    };

    const menuArray = () => {
        const array = [];
        headerObject.forEach(element => {
            if (element.type === types.menu) {
                array.push(
                    <Menu
                        key={element.name + '-menu'}
                        id={element.name + '-menu'}
                        anchorEl={menuState[element.name].anchorEl}
                        open={menuState[element.name].open}
                        classes={{paper: classes.menu}}
                        onClose={() => setMenuState({...menuState, [element.name]: {anchorEl: null, open: false}})}
                        elevation={0}
                        keepMounted
                    >
                        {element.menuItems.map(menuItem => (
                            <MenuItem
                                key={menuItem.name + '-menuItem'}
                                className={props.link === menuItem.path ? [classes.menuItem, classes.menuItemSelected].join(' ') : classes.menuItem}
                                onClick={() => {
                                    setMenuState({
                                        ...menuState,
                                        [element.name]: {open: false, anchorEl: null}
                                    }); 
                                    handleNav(menuItem.path);
                                }}
                                selected={props.link === menuItem.path}
                                component={Link}
                                to={menuItem.path}
                            >
                                {menuItem.name}
                            </MenuItem>
                        ))}
                    </Menu>
                );
            }
        });

        return array;
    };

    const tabs = (
        <Tabs
            value={miscPaths.includes(props.link) ? headerObject.length + 1 : getTabValue(headerObject, props.link)}
            className={classes.tabContainer}
        >
            {tabArray()}
            {props.isAuth ?
                <Tab label="Logout" className={classes.tab} onClick={logoutHandler} component={Link} to={"/"}/> :
                <Tab label="Login" className={classes.tab} onClick={() => handleNav('/login')} component={Link} to={"/login"}/>}
            <Tab className={classes.emptyTab} />
        </Tabs>
    );

    const header = (
        <Fragment>
            {tabs}
            {menuArray()}
        </Fragment>
    );

    return (
        <Fragment>
            <HideOnScroll {...props}>
                <AppBar position="fixed">
                    <Toolbar disableGutters>
                        <div className={classes.logoContainer} onClick={() => {props.history.push('/'); handleNav('/');}}>
                        </div>
                        {matches ? drawer : header}
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <div className={classes.toolbarMargin} />
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
        link: state.general.link,
        role: state.auth.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        signUserOut: () => dispatch(actions.signUserOut()),
        setCurrentPath: (link) => dispatch(actions.setCurrentPath(link))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));