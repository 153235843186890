import * as actionTypes from './actionTypes';
import firebase from '../../Firebase';
import {itemsPerPage} from '../../settings';

export const classFetchStart = () => {
    return {
        type: actionTypes.classFetchStart
    };
};

export const classFetchSuccess = (classPrayers) => {
    return {
        type: actionTypes.classFetchSuccess,
        classPrayers: classPrayers
    };
};

export const classFetchFail = (error) => {
    return {
        type: actionTypes.classFetchFail,
        error: error
    };
};

export const classSendStart = () => {
    return {
        type: actionTypes.classSendStart
    };
};

export const classSendSuccess = () => {
    return {
        type: actionTypes.classSendSuccess
    };
};

export const classSendFail = (error) => {
    return {
        type: actionTypes.classSendFail,
        error: error
    };
};

export const sendPrayerRequest = (prayer, path) => {
    return dispatch => {
        dispatch(classSendStart());
        const ref = firebase.database().ref().child(path);
        ref.push(prayer)
            .then(() => {
                dispatch(classSendSuccess());
            })
            .catch((error) => {
                dispatch(classSendFail());
            });
    };
};

export const fetchClassPrayers = (filterOpts) => {
    return dispatch => {
        dispatch(classFetchStart());
        
        // Ref to the database location
        const ref = firebase.database().ref(filterOpts.path);
        // Remove the listener for that location (since a new listener will be added)
        ref.off();

        switch (filterOpts.type) {
            case 0: // recent prayers
                const twoWeeksAgo = new Date().setDate(new Date().getDate() - 14);
                ref.orderByChild('date').startAt(twoWeeksAgo).limitToLast((itemsPerPage * filterOpts.page) + 1).on('value', (snapshot) => {
                    const prayerObj = snapshot.val();
                    const prayerArr = [];

                    if (prayerObj) {
                        Object.keys(prayerObj).forEach(key => {
                            // Filter out answered prayer requests.
                            if (!prayerObj[key].answered) {
                                prayerArr.push({...prayerObj[key], key: key});
                            }
                        });
                    }

                    prayerArr.reverse();
                    dispatch(classFetchSuccess(prayerArr));
                });
                break;
            case 1: // answered prayers
                ref.orderByChild('answered').equalTo(true).limitToLast((itemsPerPage * filterOpts.page) + 1).on('value', (snapshot) => {
                    const prayerObj = snapshot.val();
                    const prayerArr = [];

                    if (prayerObj) {
                        Object.keys(prayerObj).forEach(key => {
                            prayerArr.push({...prayerObj[key], key: key});
                        });
                    }

                    prayerArr.reverse();
                    dispatch(classFetchSuccess(prayerArr));
                });
                break;
            case 2: // all prayers
                ref.orderByChild('date').limitToLast((itemsPerPage * filterOpts.page) + 1).on('value', (snapshot) => {
                    const prayerObj = snapshot.val();
                    const prayerArr = [];

                    if (prayerObj) {
                        Object.keys(prayerObj).forEach(key => {
                            prayerArr.push({...prayerObj[key], key: key});
                        });
                    }

                    prayerArr.reverse();
                    dispatch(classFetchSuccess(prayerArr))
                });
                break;
            case 3:
                ref.orderByChild('user').equalTo(filterOpts.uid).limitToLast((itemsPerPage * filterOpts.page) + 1).on('value', (snapshot) => {
                    const prayerObj = snapshot.val();
                    const prayerArr = [];
                    
                    if (prayerObj) {
                        Object.keys(prayerObj).forEach(key => {
                            prayerArr.push({...prayerObj[key], key: key});
                        });
                    }

                    prayerArr.reverse();
                    dispatch(classFetchSuccess(prayerArr));
                });
                break;
            case 4: // unanswered prayers
                ref.orderByChild('answered').equalTo(false).limitToLast((itemsPerPage * filterOpts.page) + 1).on('value', (snapshot) => {
                    const prayerObj = snapshot.val();
                    const prayerArr = [];

                    if (prayerObj) {
                        Object.keys(prayerObj).forEach(key => {
                            prayerArr.push({...prayerObj[key], key: key});
                        });
                    }

                    prayerArr.reverse();
                    dispatch(classFetchSuccess(prayerArr));
                });
                break;
            default:
                break;
        }
    };
};