import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.paper
    },
    divider: {
        backgroundColor: theme.palette.secondary.main
    }
}));


const PrayerCard = (props) => {
    const classes = useStyles();

    const signUpPrompt = (
        <Typography variant="body1">
            Sign in to add and view prayer requests
        </Typography>
    );

    const prayerStats = (
        <Fragment>
            <Typography variant="body1">
                Total Prayers: {props.total}
            </Typography>
            <Typography variant="body1">
                Total Answered Prayers: {props.totalAnswered}
            </Typography>
        </Fragment>
    );

    return (
        <Card variant="outlined" className={classes.root}>
            <CardHeader
                title={props.title !== 'unknown' ? props.title : "Personal"}
                titleTypographyProps={{className: classes.root}}
                subheader={props.subheader}
                subheaderTypographyProps={{className: classes.root}}
                />
            <Divider className={classes.divider}/>
            <CardContent style={{padding: '16px'}} >
                {props.isAuth ? prayerStats : signUpPrompt}
            </CardContent>
        </Card>
    );
};

export default PrayerCard;