import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Document from '@material-ui/icons/InsertDriveFileRounded';
import Public from '@material-ui/icons/PublicRounded';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '20px 0',
        minHeight: '500px'
    },
    body: {
        flex: '1'
    },
    bodyButton: {
        alignSelf: 'center',
        width: '80%',
        color: theme.palette.background.paper
    },
    cardContent: {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        width: '100%'
    },
    container: {
        backgroundColor: theme.palette.secondary.main,
        minHeight: '275px',
        color: 'white',
        boxSizing: 'border-box',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.spacing(1)
    },
    avatar: {
        backgroundColor: 'transparent',
        paddingBottom: theme.spacing(2)
    },
    icon: {
        fontSize: '40px'
    },
    subheader: {
        color: theme.palette.background.paper
    }
}));

const linkArray = [
    {title: 'Scott Pauley Podcast - Enjoying the Journey', link: 'https://podcasts.apple.com/us/podcast/enjoying-the-journey/id1329674849', type: 'link'}
];

const Resources = (props) => {
    const classes = useStyles();

    const links = linkArray.map(link => {
        return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={link.title}>
                <Paper variant='elevation' elevation={4} className={classes.container}>
                    <Avatar aria-label="link icon" className={classes.avatar} size='large'>
                        {link.type === 'document' ? <Document className={classes.icon}/> : <Public className={classes.icon}/>}
                    </Avatar>
                    <Typography variant='h5' className={classes.body}>
                        {link.title}
                    </Typography>
                    <Button variant='outlined' className={classes.bodyButton} color='inherit' href={link.link} rel="noopener noreferrer" target={link.type === 'document' ? null : "_blank"}>
                        {link.type === 'document' ? 'Download File' : 'Visit Link'}
                    </Button>
                </Paper>
            </Grid>
        );
    })

    return (
        <Grid container className={classes.root} justify='center'>
            <Grid item container xs={11} className={null} justify='flex-start' alignItems='flex-start' spacing={2} >
                <Grid item container xs={12}>
                    <Card variant="outlined" className={classes.cardContent}>
                        <CardHeader
                            title='Class Resources'
                            subheader='Ladies With Purpose - CBF'
                            subheaderTypographyProps={{className: classes.subheader}}
                        />
                    </Card>
                </Grid>
                {links}
            </Grid>
        </Grid>
    );
};

export default Resources;