import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import * as actions from '../../store/actions';
import { connect } from 'react-redux';
import firebase from '../../Firebase';

const useStyles = makeStyles(theme => ({
    columnHeader: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    footer: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '8rem',
        display: 'flex',
        alignItems: 'center'
    },
    link: {
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '0.75rem',
        textDecoration: 'none'
    }
}));

const Footer = (props) => {
    const classes = useStyles();

    const info = (
        <Fragment>
            <Grid item className={classes.columnHeader}>
                Info
            </Grid>
            <Grid item className={classes.link} component={Link} to={'/privacy'} onClick={() => props.setCurrentPath('/privacy')}>
                Privacy
            </Grid>
            <Grid item className={classes.link} component={Link} to={'/contact'} onClick={() => props.setCurrentPath('/contact')}>
                Contact
            </Grid>
        </Fragment>
    );

    return (
        <footer className={classes.footer}>
            <Grid container justify='center'>
                <Grid item container justify='center' xs={12} sm={9} md={6} lg={5} xl={5}>

                    <Grid item container xs={12} justify='center' alignItems='flex-end'>
                        {/* Main */}
                        <Grid item container direction='column' xs={4} justify='center' alignItems='center' spacing={1}>
                            <Grid item className={classes.columnHeader}>
                                Main
                            </Grid>
                            <Grid item className={classes.link} component={Link} to={'/'} onClick={() => props.setCurrentPath('/')}>
                                Home
                            </Grid>
                            {props.isAuth ?
                            <Grid item className={classes.link} component={Link} to={'/'} onClick={() => {firebase.auth().signOut(); props.setCurrentPath('/');}}>
                                Logout
                            </Grid> :
                            <Grid item className={classes.link} component={Link} to={'/login'} onClick={() => props.setCurrentPath('/login')}>
                                Login
                            </Grid>}
                        </Grid>
                        {/* Class */}
                        <Grid item container direction='column' xs={4} justify='center' alignItems='center' spacing={1}>
                            <Grid item className={classes.columnHeader}>
                                Class
                            </Grid>
                            <Grid item className={classes.link} component={Link} to={'/classprayers'} onClick={() => props.setCurrentPath('/classprayers')}>
                                Prayer Journal
                            </Grid>
                            <Grid item className={classes.link} component={Link} to={'/resources'} onClick={() => props.setCurrentPath('/resources')}>
                                Resources
                            </Grid>
                        </Grid>
                        {/* Info */}
                        <Grid item container direction='column' xs={4} justify='center' alignItems='center' spacing={1}>
                            {info}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </footer>
    );
};

const mapStateToProps = state => {
    return {
        link: state.general.link,
        isAuth: state.auth.isAuth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentPath: (link) => dispatch(actions.setCurrentPath(link))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);