import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './utilities';

const initialState = {
    classPrayers: [],
    error: null,
    loading: false
};

const classFetchStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
};
const classFetchSuccess = (state, action) => {
    return updateObject(state, {classPrayers: action.classPrayers, loading: false});
};

const classFetchFail = (state, action) => {
    return updateObject(state, {error: action.error, loading: false});
};

const classSendStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
};

const classSendSuccess = (state, action) => {
    return updateObject(state, {loading: false});
};

const classSendFail = (state, action) => {
    return updateObject(state, {error: action.error, loading: false});
};

const clearPrayers = (state, action) => {
    return updateObject(state, {classPrayers: []});
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.classFetchStart: return classFetchStart(state, action);
        case actionTypes.classFetchSuccess: return classFetchSuccess(state, action);
        case actionTypes.classFetchFail: return classFetchFail(state, action);
        case actionTypes.classSendStart: return classSendStart(state, action);
        case actionTypes.classSendSuccess: return classSendSuccess(state, action);
        case actionTypes.classSendFail: return classSendFail(state, action);
        case actionTypes.clearPrayers: return clearPrayers(state, action);
        default: return state;
    }
};

export default reducer;