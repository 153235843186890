import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    signupButton: {
        borderRadius: '50px'
    },
    welcomeCard: {
        textAlign: 'center',
        borderRadius: '15px',
        padding: '25px 0'
    }
}));

const SignUpPrompt = (props) => {
    const classes = useStyles();

    return (
        <Card raised className={classes.welcomeCard}>
            <CardContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5" >Sign up to join your classmates!</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">Share prayer requests, find upcoming events, and more!</Typography>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.signupButton}
                            component={Link}
                            to="/login"
                            onClick={() => props.clicked('/')}>
                            Sign Up
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card> 
    );
};

export default SignUpPrompt;