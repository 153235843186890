import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import background from '../assets/background.jpg';
import { withRouter, Link } from 'react-router-dom';
import {connect} from 'react-redux';
import SignUpCard from './cards/SignUpPrompt';
import * as actions from '../store/actions';

const useStyles = makeStyles(theme => ({
    background: {
        backgroundImage:`url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '460px',
        width: '100%',
        backgroundColor: '#eeeeee'
    },
    cardGrid: {
        height: '100%'
    },
    signupButton: {
        borderRadius: '50px'
    },
    welcomeCard: {
        textAlign: 'center',
        borderRadius: '15px',
        padding: '25px 0'
    }
}));

const HomePage = (props) => {
    const classes = useStyles();
    const name = props.name;

    const authedCard = (
        <Card raised className={classes.welcomeCard}>
            <CardContent>
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h5" >Hi{name === 'unknown' ? '' : ', ' + name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1">Check out the newest prayer requests!</Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="secondary" className={classes.signupButton} component={Link} to="/classprayers" onClick={() => props.setCurrentPath('/classprayers')}>
                            Class Prayer Requests
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    return (
        <div className={classes.background}>
            <Grid container justify="center" alignItems="center" className={classes.cardGrid}>
                <Grid item xl={4} lg={5} md={6} sm={6} xs={10}>
                    {props.isAuth ? authedCard : <SignUpCard clicked={props.setCurrentPath}/>}
                </Grid>
            </Grid>
        </div>
    )
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
        name: state.auth.userName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentPath: (link) => dispatch(actions.setCurrentPath(link))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));