import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const PrivacyPolicy = () => {
    return (
        <Grid container justify='center' alignItems='center'>
            <Grid item container xs={11} sm={10} md={8} lg={6} xl={6} spacing={2} style={{margin: '25px 0px'}}>
                <Grid item><Typography variant="h3">Privacy Policy</Typography></Grid>
                <Grid item><Typography variant="body1">Cole Troutman built CBF Ladies With Purpose as a free web app. This SERVICE is provided by Cole Troutman at no cost and is intended
                    for use as is by the Ladies With Purpose class of Christchurch Baptist Fellowship, TX.</Typography></Grid>
                <Grid item><Typography variant="body1">This page is used to inform website visitors regarding my policies with the collection, use, and
                    disclosure of Personal Information if anyone decided to use my Service.</Typography></Grid>
                <Grid item><Typography variant="body1">If you choose to use my Service, then you agree to the collection and use of information in
                    relation with this policy. The Personal Information that I collect is used for providing and
                    improving the Service. I will not use or share your information with anyone except as described
                    in this Privacy Policy.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Information Collection and Use</strong></Typography></Grid>
                <Grid item><Typography variant="body1">For a better experience while using my Service, I may require you to provide us with certain
                    personally identifiable information, including but not limited to First Name, Last Name, Email Address these items
                    are collected to facilitate user authentication as well as providing a better experience while using my Service. 
                    The information that I request is stored in Google's Firebase Realtime Database and will be retained by us and used as described in this privacy policy.</Typography></Grid>
                <Grid item><Typography variant="body1">The web app does use third party services that may collect information used to identify you. Google's Firebase is used to provide a means of authenticating the user. Google Firebase's Privacy Policy can be found at <a href="https://firebase.google.com/terms">https://firebase.google.com/terms</a></Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Cookies</strong></Typography></Grid>
                <Grid item><Typography variant="body1">Cookies are files with small amount of data that is commonly used an anonymous unique identifier.
                    These are sent to your browser from the website that you visit and are stored on your devices’s
                    internal memory.</Typography></Grid>
                <Grid item><Typography variant="body1">This Services does not uses these “cookies” explicitly. However, the web app may use third party code
                    and libraries that use “cookies” to collection information and to improve their services. You
                    have the option to either accept or refuse these cookies, and know when a cookie is being sent
                    to your device. If you choose to refuse our cookies, you may not be able to use some portions of
                    this Service.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Security</strong></Typography></Grid>
                <Grid item><Typography variant="body1">I value your trust in providing us your Personal Information, thus I am striving to use
                    commercially acceptable means of protecting it. But remember that no method of transmission over
                    the internet, or method of electronic storage is 100% secure and reliable, and I cannot
                    guarantee its absolute security.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Links to Other Sites</strong></Typography></Grid>
                <Grid item><Typography variant="body1">This Service may contain links to other sites. If you click on a third-party link, you will be
                    directed to that site. Note that these external sites are not operated by me. Therefore, I
                    strongly advise you to review the Privacy Policy of these websites. I have no control over, and
                    assume no responsibility for the content, privacy policies, or practices of any third-party
                    sites or services.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Children’s Privacy</strong></Typography></Grid>
                <Grid item><Typography variant="body1">This Service does not address anyone under the age of 13. I do not knowingly collect personal
                    identifiable information from children under 13. In the case I discover that a child under 13
                    has provided me with personal information, I will immediately delete this from our servers. If you
                    are a parent or guardian and you are aware that your child has provided us with personal
                    information, please contact me at troutmancole12@gmail.com so that I will be able to take the necessary actions.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Changes to This Privacy Policy</strong></Typography></Grid>
                <Grid item><Typography variant="body1">I may update our Privacy Policy from time to time. Thus, you are advised to review this page
                    periodically for any changes. I will notify you of any changes by posting the new Privacy Policy
                    on this page. These changes are effective immediately, after they are posted on this page.</Typography></Grid>

                <Grid item><Typography variant="body1"><strong>Contact Us</strong></Typography></Grid>
                <Grid item><Typography variant="body1">If you have any questions or suggestions about my Privacy Policy, do not hesitate to contact
                    me at troutmancole12@gmail.com.</Typography></Grid>
            </Grid>
        </Grid>
    );
}

export default PrivacyPolicy;