import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux';
import * as actions from '../../store/actions';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.background.paper
    }
}));

const AddNewPrayerCard = (props) => {
    const classes = useStyles();
    const [prayerRequest, setPrayerRequest] = useState('');

    const changeHandler = (e) => {
        setPrayerRequest(e.currentTarget.value);
    }

    const submitHandler = () => {
        const prayer = {
            answered: false,
            answeredDate: false,
            author: props.username,
            body: prayerRequest,
            date: + new Date(),
            user: props.userId,
        };
        props.sendPrayerRequest(prayer, props.path);
        props.cancel();
    };

    const options = {year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <Card variant="outlined">
            <CardHeader
                title={props.username}
                titleTypographyProps={{variant: 'body2'}}
                subheader={new Date().toLocaleDateString(undefined, options)}
                subheaderTypographyProps={{variant: 'body2'}}/>
            <Divider />
            <CardContent style={{padding: '16px'}} >
                <TextField
                    label="Add Prayer Request"
                    autoFocus
                    multiline
                    fullWidth
                    variant="outlined"
                    value={prayerRequest}
                    margin="normal"
                    className={classes.textArea}
                    onChange={(e) => changeHandler(e)}
                    />
                <Grid container justify="center" className={classes.buttonArea} spacing={2}>
                    <Grid item>
                        <Button variant="contained" color="primary" disableElevation onClick={props.cancel} className={classes.button} >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="secondary" disableElevation onClick={submitHandler} className={classes.button} >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = state => {
    return {
        userId: state.auth.userId,
        username: state.auth.userName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendPrayerRequest: (prayer, path) => dispatch(actions.sendPrayerRequest(prayer, path))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewPrayerCard);