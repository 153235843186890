import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './utilities';

const initialState = {
    link: '/'
};

const setCurrentPath = (state, action) => {
    return updateObject(state, {link: action.link});
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.setCurrentPath: return setCurrentPath(state, action);
        default: return state;
    }
};

export default reducer;