import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './utilities';

const initialState = {
    isAuth: false,
    error: null,
    loading: false,
    userName: 'unknown',
    userId: null,
    role: 0,
    authSignupSuccess: false
};

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        isAuth: true
    });
};

const authSignupSuccess = (state, action) => {
    return updateObject(state, {
        authSignupSuccess: true
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
};

const localAuthSuccess = (state, action) => {
    return updateObject(state, {
        isAuth: true
    });
};

const authSignOut = (state, action) => {
    return updateObject(state, {
        isAuth: false,
        authSuccess: false
    });
};

const resetError = (state, action) => {
    return updateObject(state, {
        error: null
    });
};

const getUserInfoStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    });
};

const getUserInfoSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        userName: action.userName,
        userId: action.userId,
        role: action.role
    });
};

const getUserInfoFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error
    });
};



const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.authStart: return authStart(state, action);
        case actionTypes.authSuccess: return authSuccess(state, action);
        case actionTypes.authFail: return authFail(state, action);
        case actionTypes.localAuthSuccess: return localAuthSuccess(state, action);
        case actionTypes.authSignOut: return authSignOut(state, action);
        case actionTypes.resetError: return resetError(state, action);
        case actionTypes.getUserInfoStart: return getUserInfoStart(state, action);
        case actionTypes.getUserInfoSuccess: return getUserInfoSuccess(state, action);
        case actionTypes.getUserInfoFail: return getUserInfoFail(state, action);
        case actionTypes.authSignupSuccess: return authSignupSuccess(state, action);
        default: return state;
    }
};

export default reducer;