import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import theme from './ui/theme';
import { Route, Switch } from 'react-router-dom';
import Footer from './ui/Footer';
import Layout from '../hoc/Layout';
import {withRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions';

//Pages
import HomePage from './HomePage';
import Login from './Login';
import ClassPrayerJournal from './ClassPrayerJournal';
import PrivatePrayerJournal from './PrivatePrayerJournal';
import UnderConstruction from './UnderConstruction';
import Resources from './Resources';
import asyncComponent from '../hoc/asyncComponent';
import Header from './ui/Header';
import PrivacyPolicy from './PrivacyPolicy';

const asyncAdmin = asyncComponent(() => {
	return import('./Admin/Admin');
});

const App = (props) => {
	return (
		<ThemeProvider theme={theme}>
			<Header />
			<Layout>
				<Switch>
					<Route exact path="/classprayers" component={ClassPrayerJournal} />
					<Route exact path="/personalprayers" component={PrivatePrayerJournal} />
					<Route exact path="/resources" component={Resources} />
					<Route exact path="/login" component={Login} />
					<Route exact path="/privacy" component={PrivacyPolicy} />
					<Route exact path="/contact" component={UnderConstruction} />
					{props.role === 5 ? <Route exact path="/admin" component={asyncAdmin} /> : null}
					<Route path="/" component={() => <HomePage />} />
				</Switch>
			</Layout>
			<Footer />
		</ThemeProvider>
  );
};

const mapStateToProps = state => {
	return {
		isAuth: state.auth.isAuth,
		link: state.general.link,
		role: state.auth.role
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onAuth: (email, password, isSignUp) => dispatch(actions.auth(email, password, isSignUp))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));