export const authStart = 'authStart';
export const authSuccess = 'authSuccess';
export const authFail = 'authFail';
export const localAuthSuccess = 'localAuthSuccess';
export const signUserOut = 'signUserOut';
export const authSignOut = 'authSignOut';
export const resetError = 'resetError';
export const getUserInfoStart = 'getUserInfoStart';
export const getUserInfoSuccess = 'getUserInfoSuccess';
export const getUserInfoFail = 'getUserInfoFail';
export const authSignupSuccess = 'authSignupSuccess';

export const setCurrentPath = 'setCurrentPath';

//Class Prayers
export const classFetchStart ='classFetchStart';
export const classFetchSuccess ='classFetchSuccess';
export const classFetchFail ='classFetchFail';
export const classSendStart ='classSendStart';
export const classSendSuccess ='classSendSuccess';
export const classSendFail ='classSendFail';
export const clearPrayers = 'clearPrayers';