import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {BrowserRouter} from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import authReducer from './store/reducers/auth';
import generalReducer from './store/reducers/general';
import classPrayerReducer from './store/reducers/classPrayers';
import thunk from 'redux-thunk';
import * as actions from './store/actions';
import {SnackbarProvider} from 'notistack';

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    general: generalReducer,
    classPrayer: classPrayerReducer
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

store.dispatch(actions.verifyAuth());

ReactDOM.render(<Provider store={store}><BrowserRouter><SnackbarProvider maxSnack={3}><App /></SnackbarProvider></BrowserRouter></Provider>, document.getElementById('root'));