import * as actionTypes from './actionTypes';
import firebase from '../../Firebase';
import crypto from 'crypto-js';
import {classId} from '../../settings';

export const authStart = () => {
    return {
        type: actionTypes.authStart
    };
};

export const authSuccess = () => {
    return {
        type: actionTypes.authSuccess
    };
};

export const localAuthSuccess = () => {
    return {
        type: actionTypes.localAuthSuccess
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.authFail,
        error: error
    };
};

export const authSignOut = () => {
    return {
        type: actionTypes.authSignOut
    };
};

export const signUserOut = () => {
    return dispatch => {
        firebase.auth().signOut();
        dispatch(authSignOut());
        dispatch(clearPrayers());
    }
};

export const getUserInfoStart = () => {
    return {
        type: actionTypes.getUserInfoStart
    };
};
export const getUserInfoSuccess = (userName, userId, role) => {
    return {
        type: actionTypes.getUserInfoSuccess,
        userName: userName,
        userId: userId,
        role: role
    };
};
export const getUserInfoFail = (error) => {
    return {
        type: actionTypes.getUserInfoFail,
        error: error
    };
};

export const clearPrayers = () => {
    return {
        type: actionTypes.clearPrayers
    };
};

export const authSignupSuccess = () => {
    return {
        type: actionTypes.authSignupSuccess
    };
};

export const verifyAuth = () => {
    return dispatch => {
        // Check local auth to keep page layout consistent through page reloads
        if (localStorage.getItem('isAuth')) {
            dispatch(localAuthSuccess());
        }

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                dispatch(authSuccess());
                dispatch(getUserInfoStart());
                firebase.database().ref(`/users/${classId}/${user.uid}`).once('value')
                    .then(response => {
                        dispatch(getUserInfoSuccess(response.val().name ? response.val().name : 'unknown', user.uid, response.val().role));
                    })
                    .catch(error => {
                        dispatch(getUserInfoFail(error.message));
                    });
            } else {
                dispatch(signUserOut());
            }
        });
    };
};

export const auth = (email, password, additional) => {
    return dispatch => {
        dispatch(authStart());
        if (additional.isSignUp) {
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .then(() => {
                    const user = firebase.auth().currentUser;
                    dispatch(verifyUser(user, additional.profile));
                })
                .catch((error) => {
                    dispatch(authFail(error.message));
                });
        } else {
            firebase.auth().signInWithEmailAndPassword(email, password)
                .then((response) => {
                    dispatch(authSuccess());
                })
                .catch((error) => {
                    dispatch(authFail(error.message));
                });
        }
    };
};

export const resetError = () => {
    return {
        type: actionTypes.resetError
    };
};

export const verifyUser = (user, profile) => {
    return dispatch => {

        const object = {
            name: profile.firstName + ' ' + profile.lastName,
            role: 3,
            joinDate: + new Date(),
            classCode: crypto.MD5(profile.code).toString(crypto.enc.Hex)
        };

        firebase.database().ref(`/users/${classId}/${user.uid}`).set(object)
            .then(() => {
                // user authed successfully
                dispatch(authSuccess());
                dispatch(authSignupSuccess());
            })
            .catch(() => {
                // user not authed successfully
                user.delete()
                    .then(() => {
                        dispatch(authFail('Class code incorrect.'));
                    })
                    .catch(() => {
                        dispatch(authFail('Class code incorrect. Something went wrong.'));
                    });
            });
    };
};