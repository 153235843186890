import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import * as actions from '../store/actions';

const withError = (WrappedComponent) => {
    const mapStateToProps = (state) => {
        return {
            error: state.auth.error
        };
    };
    
    const mapDispatchToProps = dispatch => {
        return {
            resetError: () => dispatch(actions.resetError())
        };
    };

    return connect(mapStateToProps, mapDispatchToProps)(class extends Component {
        handleClose = () => {
            this.props.resetError();
        };

        render() {
            const errorDialog = (
                <Dialog
                    open={this.props.error !== null}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText>
                            {this.props.error}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="secondary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            );
            return (
                <Fragment>
                    {this.props.error ? errorDialog : null}
                    <WrappedComponent {...this.props} />
                </Fragment>
            );
        };
    });
};



export default withError;