import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typeography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    root: {
    },
    date: {
        color: theme.palette.secondary.main
    },
    divider: {
        backgroundColor: theme.palette.secondary.main
    },
    dividerAnswered: {
        backgroundColor: theme.palette.background.paper
    },
    answeredDate: {
        paddingTop: theme.spacing(0),
        color: theme.palette.background.paper,
        fontWeight: 600
    },
    nameHeader: {
        paddingBottom: theme.spacing(0)
    },
    answered: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper
    },
    moreIcon: {
        color: theme.palette.primary.main
    },
    moreIconAnswered: {
        color: theme.palette.background.paper
    }
}));

const PrayerCard = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const answeredDate = (
        <CardHeader className={classes.answeredDate}
            subheader={'Prayer answered on: ' + props.answeredDate}
            subheaderTypographyProps={{variant: 'body2', className: props.answered ? classes.answeredDate : null}} />
    );

    const action = (
        <div>
            <IconButton className={props.answered ? classes.moreIconAnswered : classes.moreIcon} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                >
                <MenuItem onClick={() => {handleClose(); props.markAnswered(props.prayerId, !props.answered)}}>{props.answered ? 'Mark Unanswered' : 'Mark Answered!'}</MenuItem>
                <MenuItem onClick={() => {handleClose(); props.delete(props.prayerId)}}>Delete</MenuItem>
            </Menu>
        </div>
    );

    return (
        <Card raised className={props.answered ? classes.answered : null}>
            <CardHeader className={props.answered ? classes.nameHeader : null}
                title={props.author}
                titleTypographyProps={{variant: 'body1'}}
                subheader={props.date}
                subheaderTypographyProps={{variant: 'body2', className: props.answered ? classes.answered : classes.date}}
                action={props.owner || props.role > 3 ? action : null}>
            </CardHeader>
            {props.answered ? answeredDate : null}
            <Divider className={props.answered ? classes.dividerAnswered : classes.divider} />
            <CardContent style={{padding: '16px'}} >
                <Typeography variant="body1">
                    {props.body}
                </Typeography>
            </CardContent>
        </Card>
    );
};

export default PrayerCard;