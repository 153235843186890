import React from 'react';
import Grid from '@material-ui/core/Grid';
import constructionImage from '../assets/construction.jpg';

const UnderConstruction = () => {
    return (
        <div style={{height: '500px'}}>
            <Grid container justify="center" alignItems="center" style={{height: '100%'}}>
                <Grid item>
                    <div style={{height: '300px', width: '300px', backgroundImage: `url(${constructionImage})`, backgroundSize: 'cover'}}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default UnderConstruction;