import React, { useEffect, useState, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { connect } from 'react-redux';
import * as actions from '../store/actions';
import { useSnackbar } from 'notistack';
import firebase from '../Firebase';
import withError from '../hoc/withError';

const useStyles = makeStyles(theme => ({
    loginCard: {
        borderRadius: '15px',
        textAlign: 'center',
        padding: '0 25px',
        margin: '40px 0'
    },
    loginGrid: {
        height: '100%',
        width: '100%'
    },
    mainContainer: {
        width: '100%'
    },
    switchAuth: {
        padding: 0
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    root: {
        height: 'auto',
        margin: '20px',
    }
}));


const Login = (props) => {
    const classes = useStyles();
    const [isSignUp, setIsSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [classCode, setClassCode] = useState('');
    const [signUpClicked, setSignUpClicked] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    // Validation
    const [isValidForm, setIsValidForm] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [emailDidTouch, setEmailDidTouch] = useState(false);
    const [isValidPass, setIsValidPass] = useState(false);
    const [passDidTouch, setPassDidTouch] = useState(false);
    const [passMatch, setPassMatch] = useState(false);
    const [passConfirmDidTouch, setPassConfirmDidTouch] = useState(false);
    const [isValidFirst, setIsValidFirst] = useState(false);
    const [firstDidTouch, setFirstDidTouch] = useState(false);
    const [isValidLast, setIsValidLast] = useState(false);
    const [lastDidTouch, setLastDidTouch] = useState(false);
    const [isValidCode, setIsValidColde] = useState(false);
    const [codeDidTouch, setCodeDidTouch] = useState(false);
    

    const switchAuthHandler = () => {
        setIsSignUp(!isSignUp);
        window.scrollTo(0, 0);
    }

    const authHandler = () => {
        if (isSignUp) {
            setSignUpClicked(true);
        } else {
            setSignUpClicked(false);
        }
        props.auth(email, password, {isSignUp: isSignUp, profile: {firstName: firstName, lastName: lastName, code: classCode}});
    };

//#region
    const firstNameChangeHandler = (e) => {
        setFirstName(e.currentTarget.value);
        setFirstDidTouch(true);
        setIsValidFirst(e.currentTarget.value.length > 0);
    };

    const lastNameChangeHandler = (e) => {
        setLastName(e.currentTarget.value);
        setLastDidTouch(true);
        setIsValidLast(e.currentTarget.value.length > 0);
    };

    const classCodeChangeHandler = (e) => {
        setClassCode(e.currentTarget.value);
        setCodeDidTouch(true);
        setIsValidColde(e.currentTarget.value.length > 0);
    };

    const emailReg = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
    const emailChangeHandler = (e) => {
        setEmail(e.currentTarget.value);
        setEmailDidTouch(true);
        setIsValidEmail(emailReg.test(e.currentTarget.value));
    };

    const passChangeHandler = (e) => {
        setPassword(e.currentTarget.value);
        setPassDidTouch(true);
        setIsValidPass(e.currentTarget.value.length >= 6);
        setPassMatch(e.currentTarget.value === passwordConfirm);
    };

    const passConfirmChangeHandler = (e) => {
        setPasswordConfirm(e.currentTarget.value);
        setPassConfirmDidTouch(true);
        setPassMatch(e.currentTarget.value === password && e.currentTarget.value.length >= 6);
    };
////#endregion

    useEffect(() => {
        setIsValidForm(isValidEmail && isValidPass && ((passMatch && isValidFirst, isValidLast, isValidCode) || !isSignUp));
    }, [isValidEmail, isValidPass, passMatch, isSignUp, isValidFirst, isValidLast, isValidCode]);


    const passConfirm = (
        <Grid item>
            <TextField value={passwordConfirm}
                variant="outlined"
                label="Confirm Password"
                type="password"
                error={!passMatch && passConfirmDidTouch}
                onChange={(e) => passConfirmChangeHandler(e)} />
        </Grid>
    );

    useEffect(() => {
        if ((props.isAuth && !signUpClicked) || (props.authSignupSuccess && signUpClicked)) {
            props.history.push('/');
            props.setCurrentPath('/');
        }
    }, [props, signUpClicked]);

    const forgotPasswordHandler = () => {
        firebase.auth().sendPasswordResetEmail(email)
            .then(() => {
                enqueueSnackbar('Email sent to ' + email, {variant: 'success'});
            })
            .catch((error) => {
                enqueueSnackbar('Could not send email to ' + email, {variant: 'error'});
            });
    };

    const progress = (
        <Backdrop className={classes.backdrop} open={props.isLoading}>
            <CircularProgress color="primary" />
        </Backdrop>
    );

    const forgotPassword = (
        <Button onClick={forgotPasswordHandler}>
            <Typography variant="caption">
                Forgot My Password
            </Typography>
        </Button>
    );

    const nameFields = (
        <Fragment>
            <Grid item>
                <TextField error={!isValidFirst && firstDidTouch} value={firstName} variant="outlined"
                    label="First Name" onChange={(e) => firstNameChangeHandler(e)} />
            </Grid>
            <Grid item>
                <TextField error={!isValidLast && lastDidTouch} value={lastName} variant="outlined"
                    label="Last Name" onChange={(e) => lastNameChangeHandler(e)} />
            </Grid>
        </Fragment>
    );

    const classCodeTextArea = (
        <Grid item>
            <TextField error={!isValidCode && codeDidTouch} value={classCode} variant="outlined"
                label="Class Code" type="password" onChange={(e) => classCodeChangeHandler(e)} />
        </Grid>
    );

    return (
        <div>
            {progress}
            <Grid container justify="center" alignItems="center" className={classes.loginGrid}>
                <Grid item >
                    <Card raised className={classes.loginCard}>
                        <CardHeader title={isSignUp ? 'Create an Account' : 'Welcome Back!'} />
                        <CardContent className={classes.switchAuth}>
                            <Typography variant="subtitle2">{isSignUp ? 'Already have an account?' : `Don't have an account yet?`}</Typography>
                            <Button size="small" onClick={switchAuthHandler} color="primary">{!isSignUp ? 'Sign Up' : 'Sign In'}</Button>
                        </CardContent>
                        <CardContent>
                            <Grid container direction="column" spacing={2} justify="center" alignItems="center">
                                {isSignUp ? nameFields : null}
                                <Grid item>
                                    <TextField error={!isValidEmail && emailDidTouch} value={email} variant="outlined"
                                        label="Email" type="email" onChange={(e) => emailChangeHandler(e)} />
                                </Grid>
                                <Grid item>
                                    <TextField error={!isValidPass && passDidTouch} value={password} variant="outlined"
                                        label="Password" type="password" onChange={(e) => passChangeHandler(e)} helperText={(passDidTouch && !isValidPass) ? "must be at least 6 chars" : null} />
                                </Grid>
                                {isSignUp ? passConfirm : null}
                                {isSignUp ? classCodeTextArea: null}
                                <Grid item>
                                    <Button variant="outlined" color="secondary" onClick={authHandler} disabled={!isValidForm}>
                                        {isSignUp ? 'Sign Up' : 'Login'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    {isSignUp ? null : forgotPassword}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
           
    );
};

const mapStateToProps = state => {
    return {
        isAuth: state.auth.isAuth,
        isLoading: state.auth.loading,
        authSignupSuccess: state.auth.authSignupSuccess
    };
};

const mapDispatchToProps = dispatch => {
    return {
        auth: (email, password, isSignUp) => dispatch(actions.auth(email, password, isSignUp)),
        setCurrentPath: (link) => dispatch(actions.setCurrentPath(link))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withError(Login));